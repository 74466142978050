import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
            <section>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <Link classNameName="navbar-brand" to="/">
                            <img
                                className="img-fluid"
                                src="triprecharge-assets/images/logos/logo.png"
                                alt=""
                            />
                        </Link>
                        <button
                            className="d-lg-none navbar-burger btn p-0 rounded-pill"
                            style={{border:'none'}}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#nav01"
                            aria-controls="nav01"
                            aria-expanded="false"
                        >
                            <svg
                                className="text-primary"
                                width="51"
                                height="51"
                                viewbox="0 0 56 56"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="56"
                                    height="56"
                                    rx="28"
                                    fill="currentColor"
                                ></rect>
                                <path
                                    d="M37 32H19M37 24H19"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </svg>
                        </button>
                        <div className="collapse navbar-collapse ms-6" id="nav01">
                            
                            <ul className="navbar-nav me-auto">
                                {/*}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Plan My Trip</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#">Buy Travel Accessories</Link>
                                </li>
                                {*/}
                            </ul>
                            
                            <div>
                                <Link
                                    className="btn me-4 btn-sm btn-outline-light lh-sm text-dark"
                                    to="/login"
                                >Login</Link><Link
                                    className="btn btn-sm btn-primary border border-primary-dark lh-sm"
                                    to="/register"
                                >Register</Link>
                            </div>
                        </div>
                    </div>
                </nav>
                <div
                    className="d-none navbar-menu position-fixed top-0 start-0 bottom-0 w-75 mw-xs"
                    style={{zIndex:9999}}
                >
                    <div
                        className="navbar-close navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark"
                        style={{opacity: '75%'}}
                    ></div>
                    <nav
                        className="position-relative h-100 w-100 d-flex flex-column justify-content-between py-8 px-8 bg-white overflow-auto"
                    >
                        <div className="d-flex align-items-center">
                            <Link className="me-auto h4 mb-0 text-decoration-none" to="#">
                                <img
                                    className="img-fluid"
                                    src="triprecharge-assets/images/logos/logo.png"
                                    alt=""
                                />
                            </Link>
                            <Link className="navbar-close" to="#">
                                <svg
                                    width="24"
                                    height="24"
                                    viewbox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 18L18 6M6 6L18 18"
                                        stroke="#111827"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>
                                </svg>
                            </Link>
                        </div>
                        <div className="py-12">
                            <ul className="nav flex-column">
                                <li className="nav-item mb-4">
                                    <Link className="nav-link text-dark" to="/home">Plan My Trip</Link>
                                </li>
                                <li className="nav-item mb-4">
                                    <Link className="nav-link text-dark" to="#"
                                    >Buy Travel Accessories</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Link
                                className="btn d-block mb-2 btn-sm btn-outline-light text-dark"
                                to="#"
                            >Login</Link><Link
                                className="btn d-block btn-sm btn-primary border border-primary-dark"
                                to="#"
                            >Register</Link>
                        </div>
                    </nav>
                </div>
            </section>
        </>
    );
}

export default Header;